import { inject } from '@angular/core';
import { InventoryApi, PromotionListItem } from '@idealsupply/ngclient-webservice-inventory';
import { signalStore } from '@ngrx/signals';

import { withSearchResults } from 'state-data';
import { PromotionSearchFilter } from './PromotionSearchFilter';

export const PromotionSearchStore = signalStore(
  { providedIn: 'root' },
  withSearchResults<PromotionSearchFilter, PromotionListItem>(
    {
      lineCode: undefined,
      partNumber: undefined,
      vendorNum: undefined,
    },
    () => {
      const inventoryApi = inject(InventoryApi);
      return (filter, skip, limit) => {
        return inventoryApi.searchPromotions(filter.lineCode, filter.partNumber, filter.vendorNum, skip, limit);
      };
    },
  ),
);
